import React from "react";

const Home = React.lazy(() => import("../../pages/Home"));
const Help = React.lazy(() => import("../../pages/Help"));
const Download = React.lazy(() => import("../../pages/Download"));
const Admin = React.lazy(() => import("../../pages/Admin/Admin"));
const AdminAuth = React.lazy(() => import("../../pages/Admin/AdminAuth"));

export interface IRoute {
  path: string;
  component: React.ReactNode;
  exact?: boolean;
}

export enum RouteNames {
  HOME = '/',
  DOWNLOAD = '/download',
  HELP = '/help',
  ADMIN = '/admin',
  ADMIN_AUTH = '/admin/signin',
  ADMIN_HOME = '/admin/home',
  ADMIN_BRANCHES = '/admin/branches',
  ADMIN_BRANCHES_ONE = '/admin/branches/:id',
  ADMIN_MATERIALS = '/admin/materials',
  ADMIN_SETTINGS = '/admin/settings'
}

export const publicRoutes: IRoute[ ] = [
  { path: RouteNames.HOME, exact: true, component: <Home /> },
  { path: RouteNames.DOWNLOAD, exact: true, component: <Download /> },
  { path: RouteNames.HELP, exact: true, component: <Help /> },
  { path: RouteNames.ADMIN, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_HOME, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_BRANCHES, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_BRANCHES_ONE, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_MATERIALS, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_SETTINGS, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_AUTH, exact: true, component: <AdminAuth /> }
]

export const twRoutes: IRoute[ ] = []
