import {observer} from "mobx-react-lite";
import React, { FC, ReactElement, useState } from "react";
import {XImage} from "../core/models/XImage";
import {SliderToggleDirection} from "../core/utils/defaults";

import styles from "../styles/ModalImageView.module.scss";

interface ModalProps {
  visible: boolean
  images: XImage[]
  selectedIndex: number
  onToggleImage: (dir: SliderToggleDirection) => void
  onClose: () => void
}

const ImageViewModal: FC<ModalProps> = ({visible, images, selectedIndex, onToggleImage, onClose}) => {

  const [ hidingProceed, setHidingProceed ] = useState(false);

  const onKeydown = ({ key }: KeyboardEvent) => {
    switch (key) {
      case 'Escape':
        setHidingProceed(true);
        setTimeout(() => {
          onClose();
          setHidingProceed(false);
        }, 300);
        break;
    }
  }

  const onCloseRef = () => {
    setHidingProceed(true);
    setTimeout(() => {
      onClose();
      setHidingProceed(false);
    }, 300);
  }

  React.useEffect(() => {
    document.addEventListener('keydown', onKeydown)
    return () => document.removeEventListener('keydown', onKeydown)
  })

  if (!visible) return null
  
  return (
    <div className={styles.modal} style={{opacity: hidingProceed ? "0" : "1"}}>
      <div className={styles.modal_close} onClick={onCloseRef}><i className="far fa-xmark"></i></div>
      
      {images.length > 1 ? <>
        <div className={styles.modal_prev} onClick={() => onToggleImage("prev")}><i className="far fa-chevron-left"></i></div>
        <div className={styles.modal_next} onClick={() => onToggleImage("next")}><i className="far fa-chevron-right"></i></div>
      </> : <></>}
    
      <div className={styles.modal_image}>
        <div className={styles.image}><img src={images[selectedIndex].url} /></div>
        <div className={styles.title}>{images[selectedIndex].title}</div>
      </div>
    </div>
  )
}

export default observer(ImageViewModal)