import React, { FC, ReactElement, useState } from "react";

import styles from "../styles/Modal.module.scss";

interface ModalProps {
  visible: boolean
  title: string
  content: ReactElement | string
  footer?: ReactElement | string
  maxWidth?: string
  closable?: boolean
  onClose: () => void
}

const Modal: FC<ModalProps> = ({visible, title, content, footer, closable = true, maxWidth = "550px", onClose}) => {

  const [ hidingProceed, setHidingProceed ] = useState(false);

  const onKeydown = ({ key }: KeyboardEvent) => {
    switch (key) {
      case 'Escape':
        if (closable) {
          setHidingProceed(true)
          setTimeout(() => {
            onClose()
            setHidingProceed(false)
          }, 300)
        }
        break
    }
  }

  const onCloseRef = () => {
    setHidingProceed(true);
    setTimeout(() => {
      onClose();
      setHidingProceed(false);
    }, 300);
  }

  React.useEffect(() => {
    document.addEventListener('keydown', onKeydown)
    return () => document.removeEventListener('keydown', onKeydown)
  })

  if (!visible) return null
  
  return (
    <div className={styles.modal} style={{opacity: hidingProceed ? "0" : "1"}}>
      <div className={styles.modal_dialog} style={{maxWidth: maxWidth}} onClick={e => e.stopPropagation()}>
        <div className={styles.modal_header}>
          <h3 className={styles.modal_header__title}
            style={{
              textAlign: parseInt(maxWidth) > 420 ? "center" : "center"
            }}>{title}</h3>
          <span style={{display: closable ? "block" : "none"}} className={styles.modal_header__close} onClick={onCloseRef}>
            <i className="fas fa-times"></i>
          </span>
        </div>
        <div className={styles.modal_body}
          style={{
            padding: parseInt(maxWidth) > 420 ? "0px 0px" : "0px"
          }}>
          <div className={styles.modal_body__content}>{content}</div>
        </div>
        {footer && <div className={styles.modal_footer}>{footer}</div>}
      </div>
    </div>
  )
}

export default Modal