import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import {useLocation, useNavigate} from "react-router-dom"
import {Context} from ".."
import useWindowDimensions from "../core/hooks/useWindowDimensions"
import {HomeMenuPage} from "../core/utils/defaults"
import {isNullOrUndefined} from "../core/utils/utils"

import styles from "../styles/Home.module.scss"

interface HeaderProps {
  isMobile?: boolean
  onOpenMenu?: any
  goToSection?: any
  onBackCall?: any
}

const Header: FC<HeaderProps> = ({
  onOpenMenu, goToSection, onBackCall, isMobile = false
}) => {

  const { width, height } = useWindowDimensions()
  const { store } = useContext(Context)

  const navigate = useNavigate();
  const location = useLocation();

  const [headerToggled, setHeaderToggled] = useState(false);
  const [anim1, setAnim1] = useState(false);
  const [anim2, setAnim2] = useState(false);
  const [anim3, setAnim3] = useState(false);
  const [anim4, setAnim4] = useState(false);

  const goTop = () => {
    if (store.menuSelected != 0) {
      store.setIsHomeBlockVisible(false);
      store.setIsHeaderLocked(false);
      store.setSelectedSlide(0);
      setTimeout(() => {
        store.setMenuSelected(0);
        setTimeout(() => {
          store.setIsHomeBlockVisible(true);
        }, 100);
      }, 200);
    }

    const section = document.querySelector(`#top`);
    if (!isNullOrUndefined(section)) {
      section.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  const navTo = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    
    let path = "";

    if (width <= 768) {
      if (event.target.nodeName.toLowerCase() == "a") {
        path = event.target.pathname;
      } else if (event.target.nodeName.toLowerCase() == "svg") {
        path = event.target.parentElement.pathname;
      } else if (event.target.nodeName.toLowerCase() == "path") {
        path = event.target.parentElement.parentElement.pathname;
      }
    } else {
      if (event.target.nodeName.toLowerCase() == "img") {
        path = event.target.parentElement.pathname;
      } else {
        path = event.target.pathname;
      }
    }

    console.log(width, path, event);

    store.setIsFaderVisible(true);
    setTimeout(() => {
      store.setIsFaderShown(true);

      setTimeout(() => {
        navigate(path);
      }, 300);
    }, 100);
  }

  useEffect(() => {
    const onScroll = (event: any) => {
      console.log(event.target.scrollingElement.scrollTop);
      const scrollTop = event.target.scrollingElement.scrollTop;
      if (scrollTop >= 18) {
        setHeaderToggled(true);
      } else {
        setHeaderToggled(false);
      }
    }

    setTimeout(() => {
      setAnim1(true);
      setTimeout(() => {
        setAnim2(true)
        setTimeout(() => {
          setAnim3(true)
          setTimeout(() => {
            setAnim4(true)
          }, 100)
        }, 100)
      }, 200)
    }, 500)
    
    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    }
  }, []);

  return (
    <>
      <div className="container" style={{display: location.pathname.includes("/signin") || location.pathname.includes("/admin") ? "none" : "block"}}>
        <div className="row">
          <div className="col-12">
            <div className={styles.centered_wrap}>
              <div className={styles.flexed_head}>
                <div className={styles.start}>
                  <a className={styles.logo} href="/" onClick={navTo}><img src="/assets/images/logo.svg" style={{opacity: anim1 ? "1" : "0"}} /></a>
                  <div className={styles.fh_data} style={{
                    opacity: location.pathname == "/" ? "0" : "1"
                  }}>
                    <div style={{opacity: anim1 ? "1" : "0"}} className={styles.title}>Ads Network</div>
                    <div style={{opacity: anim1 ? "1" : "0"}} className={styles.text}>Официальная рекламная сеть компании TOPGUN</div>
                  </div>
                </div>
                <div className={styles.menu}>
                  <div className={styles.menu_wrap}>
                    <a style={{opacity: anim2 ? "1" : "0"}} href="/" className={location.pathname == "/" ? styles.active : ""} onClick={navTo}>Главная</a>
                    <a style={{opacity: anim3 ? "1" : "0"}} href="/download" className={location.pathname == "/download" ? styles.active : ""} onClick={navTo}>Скачать</a>
                    <a style={{opacity: anim4 ? "1" : "0"}} href="/help" className={location.pathname == "/help" ? styles.active : ""} onClick={navTo}>Помощь</a>
                  </div>
                </div>
                <div className={styles.mob_menu}>
                  <div className={styles.mm_wrap}>
                    <a style={{opacity: anim2 ? "1" : "0"}} href="/" className={location.pathname == "/" ? styles.active : ""} onClick={navTo}><i className="far fa-home"></i></a>
                    <a style={{opacity: anim3 ? "1" : "0"}} href="/download" className={location.pathname == "/download" ? styles.active : ""} onClick={navTo}><i className="far fa-cloud-arrow-down"></i></a>
                    <a style={{opacity: anim4 ? "1" : "0"}} href="/help" className={location.pathname == "/help" ? styles.active : ""} onClick={navTo}><i className="far fa-circle-question"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(Header)