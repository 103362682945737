import { FC, useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import AppRouter from './components/AppRouter';

import './styles/App.scss';
import MobileMenu from './components/MobileMenu';
import Header from './components/Header';
import {isNullOrUndefined} from './core/utils/utils';
import Modal from './components/Modal';

import InputMask from "react-input-mask"
import {Context} from '.';
import useWindowDimensions from './core/hooks/useWindowDimensions';
import Footer from './components/Footer';
import ImageViewModal from "./components/ModalImageView"
import {SliderToggleDir, SliderToggleDirection} from './core/utils/defaults';

const App: FC = () => {

  const { store } = useContext(Context);
  
  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    console.log(width);
    if (width <= 960) {
      store.setIsMobile(true);
    } else {
      store.setIsMobile(false);
    }
  }, [width, height]);

  useEffect(() => {
    if (width <= 960) {
      store.setIsMobile(true);
    } else {
      store.setIsMobile(false);
    }

    setTimeout(() => {
      store.setIsFaderShown(false);
      setTimeout(() => {
        store.setIsFaderVisible(false);
      }, 400);
    }, 1300);
  }, []);


  return (
    <>
      <div className="fader" style={{display: store.isFaderVisible ? "block" : "none", opacity: store.isFaderShown ? "1" : "0"}}></div>
        
      <Header />

      <AppRouter />

      <Modal
        visible={store.isBackCallVisible}
        title="Подключиться к программе"
        onClose={() => store.setIsBackCallVisible(false)}
        maxWidth={"420px"}
        content={<>
          <div className="modal_text">
            <p>Укажите Ваши имя и телефон.<br/>Наш менеджер свяжется с Вами в ближайшее время!</p>
          
            <div className="modal_form">
              <div>
                <input type="text" placeholder="Имя" value={userName} onChange={(event) => setUserName(event.target.value)} />
              </div>
              <div>
                <InputMask mask="+7 999 999 9999" placeholder="Телефон" value={userPhone} onChange={(event: any) => setUserPhone(event.target.value)} />
              </div>
            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => store.setIsBackCallVisible(false)}>Отправить</button>
            </div>
          </div>
        </>} />

      <ImageViewModal
        visible={store.isImageViewVisible}
        images={store.selectedImages}
        selectedIndex={store.selectedImageIndex}
        onToggleImage={(dir: SliderToggleDirection) => {
          if (dir == SliderToggleDir.NEXT) {
            if (store.selectedImageIndex < (store.selectedImages.length - 1)) {
              store.setSelectedImageIndex(store.selectedImageIndex + 1)
            } else {
              store.setSelectedImageIndex(0)
            }
          } else {
            if (store.selectedImageIndex > 0) {
              store.setSelectedImageIndex(store.selectedImageIndex - 1)
            } else {
              store.setSelectedImageIndex(store.selectedImages.length - 1)
            }
          }
        }}
        onClose={() => store.setIsImageViewVisible(false)} />
    </>
  )
}

export default observer(App)

/*
<MobileMenu 
        isOpen={store.isMenuOpenned} 
        isMobile={store.isMobile}
        onMenuClosed={() => store.setIsMenuOpenned(false)} />
<Footer />
      <Header
        isMobile={store.isMobile}
        onOpenMenu={() => openMenu()}
        goToSection={(selector: string) => goToSection(selector)}
        onBackCall={() => {
          store.setIsBackCallVisible(true);
        }} />
*/
